<template>
    <!-- 新增场地 -->
    <div class="field_info_box">
        <!-- 面包屑导航 -->
        <bread-crumb :breadcrumb="breadcrumb"></bread-crumb>
        <!-- 表单数据部分 -->
        <div class="field_info_form">
            <!-- 基本信息 -->
            <div class="field_info_form_title">基本信息</div>
            <el-form :model="form" label-width="150px">
                <!-- 场地LOGO -->
                <el-form-item label="场地LOGO">
                    <el-upload
                        class="field-logo-uploader"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        :show-file-list="false"
                        :on-success="handleLogoSuccess"
                        :before-upload="beforeLogoUpload">
                        <img v-if="form.logo" :src="form.logo" class="field-logo">
                        <i v-else class="el-icon-plus field-logo-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <!-- 场地名称 -->
                <el-form-item label="场地名称">
                    <el-input
                        v-model="form.goods_name"
                        class="field_info_form_input"
                        placeholder="请填写">
                    </el-input>
                </el-form-item>
                <!-- 轮播图 -->
                <el-form-item label="轮播图">
                    <el-upload
                        class="field-carousel-uploader"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        list-type="picture-card"
                        :on-preview="handleCarouselPreview"
                        :on-remove="handleRemove">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <span class="field_info_explain_text">图片尺寸(宽高比)：16:7；</span>
                </el-form-item>
                <!-- 场地费用 -->
                <el-form-item label="场地费用">
                    <el-input
                        v-model="form.price"
                        class="field_info_form_input"
                        placeholder="请填写">
                    </el-input>
                </el-form-item>
            </el-form>
            <!-- 场地介绍 -->
            <div class="field_info_form_title">场地介绍</div>
            <el-form :model="form" label-width="150px">
                <!-- 场地图片 -->
                <el-form-item label="场地图片">
                    <el-upload
                        class="field-img-uploader"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        :show-file-list="false"
                        :on-success="handleImgSuccess"
                        :before-upload="beforeImgUpload">
                        <img v-if="form.goods_img" :src="form.goods_img" class="field-img">
                        <i v-else class="el-icon-plus field-img-uploader-icon"></i>
                    </el-upload>
                    <span class="field_info_explain_text">图片尺寸(宽高比)：18:11；仅一张</span>
                </el-form-item>
                <!-- 场地介绍 -->
                <el-form-item label="场地介绍"></el-form-item>
                <!-- 场地专辑 -->
                <el-form-item label="场地专辑">
                    <el-upload
                        action="https://jsonplaceholder.typicode.com/posts/"
                        list-type="picture-card"
                        :on-preview="handleAlbumPreview"
                        :on-remove="handleRemove">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <!-- 联系方式 -->
            <div class="field_info_form_title">联系方式</div>
            <el-form :model="form" label-width="150px">
                <!-- 地址 -->
                <el-form-item label="地址">
                    <el-input
                        v-model="form.address"
                        class="field_info_form_input"
                        placeholder="请填写">
                    </el-input>
                </el-form-item>
                <!-- 电话 -->
                <el-form-item label="电话">
                    <el-input
                        v-model="form.mobile"
                        class="field_info_form_input"
                        placeholder="请填写">
                    </el-input>
                </el-form-item>
                <!-- 微信 -->
                <el-form-item label="微信">
                    <el-input
                        v-model="form.wechat"
                        class="field_info_form_input"
                        placeholder="请填写">
                    </el-input>
                </el-form-item>
                <!-- 是否上架 -->
                <el-form-item>
                    <el-checkbox v-model="form.is_sale">将此场地下架</el-checkbox>
                    <span class="field_info_explain_text">(勾选后场地将无法进行租赁)</span>
                </el-form-item>
                <!-- 按钮 -->
                <el-form-item>
                    <el-button type="warning">保存</el-button>
                    <el-button type="warning" plain>取消</el-button>
                </el-form-item>
            </el-form>
            <!-- 模态框 -->
            <img-dialog></img-dialog>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    data() {
        return {
            // 面包屑导航参数
            breadcrumb: [
                {
                    path: "/center/field",
                    path_name: "场地管理",
                },
                {
                    path: "/center/field/info",
                    path_name: "新增场地",
                },
            ],
            // 表单数据
            form: {
                logo: "",
                goods_name: "",
                carousel: "",
                price: "",
                goods_img: "",
                summary: "",
                album: [],
                address: "",
                mobile: "",
                wechat: "",
                is_sale: "",
            },
        }
    },
    computed: mapState({
        // 模态框控制器
        dialogVisible: state => state.dialogVisible,
    }),
    components: {
        // 面包屑导航组件
        breadCrumb: () => import('@/components/breadCrumb'),
        // 图片模态框
        imgDialog: () => import('@/components/imgDialog'),
    },
    methods: {
        ...mapMutations([
            "changeDialogVisible",
            // 图片展示模态框地址写入
            "writeDialogImgUrl",
        ]),
        // logo上传成功钩子函数
        handleLogoSuccess(res, file) {
            this.form.logo = URL.createObjectURL(file.raw);
        },
        // logo上传之前钩子函数
        beforeLogoUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        // 轮播图点击放大查看钩子函数
        handleCarouselPreview(file){
            this.writeDialogImgUrl(file.url);
            this.changeDialogVisible(true);
        },
        // 移除钩子函数
        handleRemove(file, fileList){
            console.log(file, fileList);
        },
        // 场地图片上传成功钩子函数
        handleImgSuccess(res, file){
            this.form.goods_img = URL.createObjectURL(file.raw);
        },
        // 场地图片上传之前钩子函数
        beforeImgUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        // 场地专辑点击放大查看钩子函数
        handleAlbumPreview(file){
            this.writeDialogImgUrl(file.url);
            this.changeDialogVisible(true);
        },
    },
}
</script>

<style>
/* element-ui */
.field_info_form_input{
    width: 200px;
}
/* logo区域 */
.field-logo-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.field-logo-uploader .el-upload:hover {
    border-color: #409EFF;
}
.field-logo-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}
.field-logo {
    width: 100px;
    height: 100px;
    display: block;
}
/* 轮播图区域 */
.field-carousel-uploader .el-upload-list--picture-card .el-upload-list__item{
    width: 338px;
}
/* 场地图片 */
.field-img-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.field-img-uploader .el-upload:hover {
    border-color: #409EFF;
}
.field-img-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 234px;
    height: 143px;
    line-height: 143px;
    text-align: center;
}
.field-img {
    width: 234px;
    height: 143px;
    display: block;
}

/* 相机信息表单部分 */
.field_info_form{
    margin-top: 30px;
}
/* 场地信息表单标题 */
.field_info_form_title{
    font-size: 20px;
    font-weight: bold;
    color: #303133;
}
/* 场地信息说明字体 */
.field_info_explain_text{
    color: #C0C4CC;
}
</style>
